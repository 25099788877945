import microApp, { getActiveApps } from '@micro-zoe/micro-app'

// 向子应用发送路由跳转信息,让子应用去跳转
export const childMicroRoute = function(index, meta, router) {
  // 获取子应用名称
  const microName = meta.microName

  console.log('[microapp.js] 现在启动的微前端: ', JSON.stringify(getActiveApps()))

  router.push(index)

  // if (!getActiveApps().includes(microName)) {
  //   // hash && (index += `/#${index + hash}`)
  //   console.log('启动微前端子应用：' + microName)
  //   router.push(index)
  // } else {
  //   // 向当前子应用派发路由信息，告诉子应用进行路由跳转
  //   // microApp.setData(microName, { path: childPath, meta })
  //   router.push(index)
  //   // 切换路由标签
  //   // router.push(index)
  // }
}

export default microApp
