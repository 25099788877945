<template>
  <div
    class="site-wrapper"
    :class="{ 'site-sidebar--fold': sidebarFold }"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="$t('loading')"
  >
    <template v-if="!loading">
      <main-navbar />
      <main-sidebar />
      <div
        class="site-content__wrapper"
        :style="{ 'min-height': documentClientHeight + 'px' }"
      >
        <main-content />
      </div>
    </template>
  </div>
</template>

<script>
import MainNavbar from "./main-navbar";
import MainSidebar from "./main-sidebar";
import MainContent from "./main-content";
import {userInfo} from '@/api/sys/user'
export default {
  data() {
    return {
      loading: true
    };
  },
  components: {
    MainNavbar,
    MainSidebar,
    MainContent
  },
  computed: {
    documentClientHeight: {
      get() {
        return this.$store.state.common.documentClientHeight;
      },
      set(val) {
        this.$store.commit("common/updateDocumentClientHeight", val);
      }
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      }
    },
    userId: {
      get() {
        return this.$store.state.user.id;
      },
      set(val) {
        this.$store.commit("user/updateId", val);
      }
    },
    userName: {
      get() {
        return this.$store.state.user.name;
      },
      set(val) {
        this.$store.commit("user/updateName", val);
      }
    },
    orgId: {
      get() {
        return this.$store.state.user.orgId;
      },
      set(val) {
        this.$store.commit("user/updateOrgId", val);
      }
    },
    orgName: {
      get() {
        return this.$store.state.user.orgName;
      },
      set(val) {
        this.$store.commit("user/updateOrgName", val);
      }
    },
    orgLevel: {
      get() {
        return this.$store.state.user.orgLevel;
      },
      set(val) {
        this.$store.commit("user/updateOrgLevel", val);
      }
    },
    deptIds: {
      get() {
        return this.$store.state.user.deptIds;
      },
      set(val) {
        this.$store.commit("user/updateDeptIds", val);
      }
    },
    deptId: {
      get() {
        return this.$store.state.user.deptId;
      },
      set(val) {
        this.$store.commit("user/updateDeptId", val);
      }
    },
    deptName: {
      get() {
        return this.$store.state.user.deptName;
      },
      set(val) {
        this.$store.commit("user/updateDeptName", val);
      }
    },
    deptType: {
      get() {
        return this.$store.state.user.deptType;
      },
      set(val) {
        this.$store.commit("user/updateDeptType", val);
      }
    },
    regionId: {
      get() {
        return this.$store.state.user.regionId;
      },
      set(val) {
        this.$store.commit("user/updateRegionId", val);
      }
    },
    regionCode: {
      get() {
        return this.$store.state.user.regionCode;
      },
      set(val) {
        this.$store.commit("user/updateRegionCode", val);
      }
    },
    gridId: {
      get() {
        return this.$store.state.user.gridId;
      },
      set(val) {
        this.$store.commit("user/updateGridId", val);
      }
    },
    gridCode: {
      get() {
        return this.$store.state.user.gridCode;
      },
      set(val) {
        this.$store.commit("user/updateGridCode", val);
      }
    },
    gridIds: {
      get() {
        return this.$store.state.user.gridIds;
      },
      set(val) {
        this.$store.commit("user/updateGridIds", val);
      }
    },
    gridCodes: {
      get() {
        return this.$store.state.user.gridCodes;
      },
      set(val) {
        this.$store.commit("user/updateGridCodes", val);
      }
    },
    adcode: {
      get() {
        return this.$store.state.user.adcode;
      },
      set(val) {
        this.$store.commit("user/updateAdCode", val);
      }
    },
    type: {
      get() {
        return this.$store.state.user.type;
      },
      set(val) {
        this.$store.commit("user/updateType", val);
      }
    },
    parentId: {
      get() {
        return this.$store.state.user.parentId;
      },
      set(val) {
        this.$store.commit("user/updateParentId", val);
      }
    },
    parentCode: {
      get() {
        return this.$store.state.user.parentCode;
      },
      set(val) {
        this.$store.commit("user/updateParentCode", val);
      }
    },
    pname: {
      get() {
        return this.$store.state.user.pname;
      },
      set(val) {
        this.$store.commit("user/updatePname", val);
      }
    },
    parentType: {
      get() {
        return this.$store.state.user.parentType;
      },
      set(val) {
        this.$store.commit("user/updateParentType", val);
      }
    },
  },
  created() {
    this.getUserInfo();
  },
  mounted() {
    this.resetDocumentClientHeight();
  },
  methods: {
    // 重置窗口可视高度
    resetDocumentClientHeight() {
      this.documentClientHeight = document.documentElement["clientHeight"];
      window.onresize = () => {
        this.documentClientHeight = document.documentElement["clientHeight"];
      };
    },
    // 获取当前管理员信息
    getUserInfo() {
      userInfo().then(({ data }) => {
        if (data && data.code === 0) {
          this.loading = false;
          this.userId = data.data.userId;
          this.userName = data.data.username;
          this.orgId = data.data.orgId;
          this.orgName = data.data.orgName;
          this.orgLevel = data.data.orgLevel;
          this.deptId = data.data.deptId;
          this.deptName= data.data.deptName;
          this.deptType= data.data.deptType; 
          this.regionId= data.data.regionId;
          this.gridId= data.data.gridId;
          this.regionCode= data.data.regionCode;
          this.gridCode= data.data.gridCode;
          this.adcode = data.data.adcode;
          this.type = data.data.type;
          this.parentId=data.data.parentId;
          this.parentCode=data.data.parentCode;
          this.pname=data.data.pname;
          this.parentType=data.data.parentType
        }
      });
    }
  }
};
</script>

<style scoped></style>
