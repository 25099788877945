<template>
  <el-submenu
    v-if="menu.list && menu.list.length >= 1 && menu.type < 1"
    :index="menu.menuId + ''"
    :popper-append-to-body="false"
    :popper-class="'site-sidebar--' + sidebarLayoutSkin + '-popper'"
  >
    <template slot="title">
      <icon-svg
        :name="menu.icon || ''"
        class="site-sidebar__menu-icon"
      ></icon-svg>
      <span>{{ menu.name }}</span>
    </template>
    <template  v-for="item in menu.list" >
      <SubMenu
        v-if="item.type < 2"
        :key="item.menuId"
        :menu="item"
        :dynamicMenuRoutes="dynamicMenuRoutes"
      >
      </SubMenu>
    </template>
  </el-submenu>
  <el-menu-item v-else :index="menu.menuId + ''" @click="gotoRouteHandle(menu)">
    <icon-svg
      :name="menu.icon || ''"
      class="site-sidebar__menu-icon"
    ></icon-svg>
    <span>{{ menu.name }}</span>
  </el-menu-item>
</template>

<script>
import SubMenu from "./sub-tree-menu";
import path from 'path';
import microApp, { getActiveApps } from '@micro-zoe/micro-app';
import { childMicroRoute } from '@/micro/microapp';
export default {
  name: "SubMenu",
  props: {
    menu: {
      type: Object,
      required: true
    },
    dynamicMenuRoutes: {
      type: Array,
      required: true
    }
  },
  components: {
    SubMenu
  },
  computed: {
    sidebarLayoutSkin: {
      get() {
        return this.$store.state.common.sidebarLayoutSkin;
      }
    }
  },
  mounted() {
    // 监听子应用路由跳转，控制菜单是否选中
    microApp.addGlobalDataListener(this.listenerMicro)
  },
  unmounted() {
    microApp.removeGlobalDataListener(this.listenerMicro)
  },
  methods: { 
    // 监听全局应用数据的方法
    listenerMicro (data) {
      const { path, microName } = data
      if(path === '/') {
        // store.commit('app/setActiveMenu', `/${microName}`)
      }else {
        // store.commit('app/setActiveMenu', `/${microName}${path}`)
      }
    }, 
    // 通过menuId与动态(菜单)路由进行匹配跳转至指定路由
    gotoRouteHandle(menu) {
      const menuroute = this.dynamicMenuRoutes.filter(
        item => item.meta.menuId === menu.menuId  
      ); 
      if (menuroute.length >= 1) {
        let route = menuroute[0];
        let meta = route.meta;
        if(meta && !meta.isMicro) {
          this.$router.push({ name: route.name, query: route.meta.query });
        } else {
          childMicroRoute(route.path, route.meta, this.$router)
        }
        return
      }

      // 判断原始路由
      const localRoute = this.dynamicMenuRoutes.filter(
        item => item.name === menu.routeName  
      );  
      
      if (localRoute.length >= 1) {
        this.$router.push({ path: menu.url });
        return
      }
    }
  }
};
</script>
<style scoped>

ul{
  background:#E9F4FD;
}
li{
  background:#5fa6fb;
  color: #fff;
}
.el-submenu {
  background: #3e88de !important;
}
.site-sidebar__inner{
  background: #1078f4  !important;
}
/deep/.el-menu-item.is-active{
  background-color: #BFE1FD !important;
  color: #333333 !important;
  border-bottom: 3px solid #ffffff !important;
}


.site-navbar__menu>.el-menu-item.is-active{color: #FFFFFF;background-color: #BFE1FD;font-weight: bold}
.el-menu-item.is-active {
  background-color: #BFE1FD;
  color:#333333 !important;
  border-bottom:  3px solid  #ffffff;
}
.el-menu-item:hover{
  color: #333 !important;
}
</style>