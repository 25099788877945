<template>
  <div class="sxfw-main-content">
    <!-- 主入口标签页 s -->
    <!--   siteContentViewHeight： {{siteContentViewHeight}} -->
    <el-row v-if="breOpen && breLayout == 'content'" class="tr-content-bre">
      <el-menu class="site-navbar__menu" mode="horizontal">
        <el-menu-item class="site-navbar__switch " index="0" @click="sidebarFold = !sidebarFold">
          <hamburger :is-active="!sidebarFold" class="zhedie_transform" />
        </el-menu-item>
      </el-menu>
      <breadcrumb></breadcrumb>
    </el-row>

    <!-- tabs -->
    <el-row v-if="tabOpen">
      <el-tabs class="sxfw-context" v-model="mainTabsActiveName" @tab-click="selectedTabHandle" @tab-remove="removeTabHandle">
        <el-tab-pane
          v-for="item in mainTabs"
          :key="item.name"
          :label="item.title"
          :name="item.name"
          :closable="item.name != 'home'"
          :class="item.name != 'home' ? 'context-home' : null">
          <div :style="siteContentViewHeight">
            <iframe v-if="item.type === 'iframe'" :src="item.iframeUrl" width="100%" height="100%" frameborder="0" scrolling="yes"></iframe>
            <!-- 如果是微前端页面不keep-alive -->
            <template v-else-if="item.meta.isMicro">
              <router-view v-if="item.name === mainTabsActiveName" />
            </template>
            <keep-alive v-else>
              <router-view v-if="item.name === mainTabsActiveName" />
            </keep-alive>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <!-- 主入口标签页 (没有tabs) -->
    <main v-else :style="siteContentViewHeight">
      <!-- 如果是微前端页面不keep-alive -->
      <template v-if="item.meta.isMicro">
        <router-view />
      </template>
      <keep-alive v-else>
        <router-view :style="siteContentViewHeight" />
      </keep-alive>
    </main>
  </div>
</template>

<script>
// depend
import breadcrumb from '@/components/breadcrumb'
import hamburger from '@/components/hamburger'
import { childMicroRoute } from '@/micro/microapp'
// component
export default {
  components: {
    breadcrumb,
    hamburger
  },
  data() {
    return {
      activeIndex: '1'
    }
  },
  computed: {
    documentClientHeight: {
      get() {
        return this.$store.state.common.documentClientHeight
      }
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold
      },
      set(val) {
        this.$store.commit('common/updateSidebarFold', val)
      }
    },
    menuLayout: {
      get() {
        return this.$store.state.common.menuLayout
      }
    },
    menuActiveName: {
      get() {
        return this.$store.state.common.menuActiveName
      },
      set(val) {
        this.$store.commit('common/updateMenuActiveName', val)
      }
    },
    mainTabs: {
      get() {
        return this.$store.state.common.mainTabs
      },
      set(val) {
        this.$store.commit('common/updateMainTabs', val)
      }
    },
    mainTabsActiveName: {
      get() {
        return this.$store.state.common.mainTabsActiveName
      },
      set(val) {
        this.$store.commit('common/updateMainTabsActiveName', val)
      }
    },
    breLayout: {
      get() {
        return this.$store.state.common.breadcrumbLayout
      }
    },
    breOpen: {
      get() {
        return this.$store.state.common.breadcrumbOpen
      }
    },
    tabOpen: {
      get() {
        return this.$store.state.common.tabOpen
      }
    },
    siteContentViewHeight() {
      var height = this.documentClientHeight
      // 减去header
      height -= 45
      if (this.tabOpen) {
        height -= 45
      }
      if (this.breOpen && this.breLayout == 'content') {
        height -= 45
      }

      return [{ minHeight: height + 'px' }, { height: height + 'px' }, { overflow: 'auto' }]
    }
  },
  methods: {
    // tabs, 选中tab
    selectedTabHandle(tab) {
      tab = this.mainTabs.filter(item => item.name === tab.name)

      if (tab.length >= 1) {
        if (tab[0].menuId) {
          const item = tab[0]
          if (item.meta.isMicro) {
            childMicroRoute(item.path, item.meta, this.$router)
          } else {
            this.$router.push({ name: tab[0].name, query: tab[0].query })
          }
        } else {
          this.$router.push({ path: tab[0].path })
        }
      }
    },
    // tabs, 删除tab
    removeTabHandle(tabName) {
      this.mainTabs = this.mainTabs.filter(item => item.name !== tabName)
      if (this.mainTabs.length >= 1) {
        // 当前选中tab被删除
        if (tabName === this.mainTabsActiveName) {
          const tab = this.mainTabs[this.mainTabs.length - 1]
          this.$router.push({ name: tab.name, query: tab.query }, () => {
            this.mainTabsActiveName = this.$route.name
          })
        }
      } else {
        this.menuActiveName = ''
        this.$router.push({ name: 'home' })
      }
    },
    // tabs, 关闭当前
    tabsCloseCurrentHandle() {
      this.removeTabHandle(this.mainTabsActiveName)
    },
    // tabs, 关闭其它
    tabsCloseOtherHandle() {
      this.mainTabs = this.mainTabs.filter(item => item.name === this.mainTabsActiveName)
    },
    // tabs, 关闭全部
    tabsCloseAllHandle() {
      this.mainTabs = []
      this.menuActiveName = ''
      this.$router.push({ name: 'home' })
    },
    // tabs, 刷新当前
    tabsRefreshCurrentHandle() {
      var tempTabName = this.mainTabsActiveName
      this.removeTabHandle(tempTabName)
      this.$nextTick(() => {
        this.$router.push({ name: tempTabName })
      })
    }
  }
}
</script>

<style lang="scss">
.sxfw-main-content {
  background-color: white;
  margin: 8px;

  .el-card {
    overflow: auto;
  }

  .tr-content-bre {
    background-color: white;
    height: 30px;
    line-height: 30px;

    .el-menu {
      .el-menu-item {
        padding: 0 10px;
        height: 30px;
        line-height: 30px;

        .icon {
          color: black !important;
        }
      }
    }
  }

  .sxfw-context>.el-tabs__header:first-child {
    .el-tabs__nav {
      .el-tabs__item {
        box-sizing: border-box;
        display: inline-block;
        list-style: none;
        font-weight: 500;
        position: relative;
        display: inline-block;
        cursor: pointer;
        height: 26px;
        line-height: 26px;
        border: 1px solid #d8dce5;
        background: #fff;
        padding: 0 8px;
        font-size: 12px;
        margin-left: 5px;
        margin-top: 4px;
        text-align: center;

        .el-icon-close:before {
          transform: scale(0.7);
        }
      }

      .el-tabs__item:first-child,
      .el-tabs__item.is-top {
        margin-left: 10px;
        padding-left: 10px;
      }

      .el-tabs__item:last-child {
        margin-right: 15px;
        padding-right: 10px;
      }

      .el-tabs__item::before {
        content: '';
        background: #fff;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: relative;
        margin-right: 2px;
      }

      .el-tabs__item.is-active {
        /*color: #409EFF;*/
        background-color: #1078f4;
        color: #fff;
        border-color: #1078f4;
      }

      .el-tabs__active-bar {
        display: none;
      }
    }
  }

  .sxfw-context>.el-tabs__header {

    /*float: left;*/
    .el-tabs__nav-scroll {
      height: 39px;
      line-height: 34px;
    }

    .el-tabs__nav-wrap is-top {
      height: 34px !important;
      line-height: 34px !important;
    }

    margin: 0 0px 3px;

    .context-home {
      padding-right: 5px;
    }

    #tab-home {
      padding-right: 20px;
    }
  }

  .el-card__header {
    font-size: 18px;
    color: #1078f4;
    font-weight: bold;
    padding: 8px 5px 1px 5px;
    border-bottom: 1px solid #ebeef5;
    box-sizing: border-box;
  }
}

// 修改的tabs上下页
.sxfw-main-content {

  .el-tabs__nav-next,
  .el-tabs__nav-prev {
    transition: all .25s ease-in-out;
    line-height: 35px;
    padding: 0 4px;

    &:hover {
      font-weight: 800;
      color: #1078f4;

      i {
        font-weight: 800;
      }
    }
  }

}

// dialog 样式
.el-dialog__wrapper .el-dialog {
  &.is-fullscreen {
    border-radius: 0;
  }

  border-radius: 2px;

  &__header {
    background-color: white;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  &__title {
    font-size: 15px !important;
    letter-spacing: 2px;
    font-weight: 800;
    color: rgb(62, 136, 222);

    &::before {
      transition: 0.25s ease-in;
      width: 0px;
      color: white;
      margin-left: -14px;
      content: '';
      display: inline-block;
      animation: fade-bling 0.55s ease-in-out 0.05s forwards;
    }
  }

  &__headerbtn {
    display: inline-block;
    position: relative;
    right: 0px;
    top: 0px;
  }

  &__headerbtn .el-icon {
    transition: transform 0.15s ease-in-out;
    font-size: 18px;

    &:hover {
      color: #f56c6c;
      font-weight: 800;
    }
  }

  &__body {
    padding: 10px;
  }

  // dialog下的分割线的样式
  .el-divider {
    background-color: #eeeeee;

    &__text {
      color: #8198c6;
      font-weight: 800;
    }
  }
}

// 覆盖样式
.form-design .el-dialog {
  .el-dialog__header {
    // background-color: white;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    .el-dialog__headerbtn {
      top: 0px;
    }
  }
}

/* 关键帧 */
@keyframes fade-bling {
  0% {
    width: 0px;
    color: white;
  }

  50%,
  80%,
  100% {
    width: 14px;
    color: rgb(62, 136, 222);
  }
}
</style>
