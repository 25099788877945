<template>
  <el-menu
    :default-active="menuActiveName || 'home'"
    :collapse="sidebarFold"
    :collapseTransition="false"
    :mode="modType"
    unique-opened
    class="site-sidebar__menu"
  >
    <el-menu-item
      index="home"
      @click="$router.push({ name: 'home' })"
      class="homeClass"
    >
      <icon-svg name="icon-home" class="site-sidebar__menu-icon"></icon-svg>
      <span slot="title">{{$t('notFound.home')}}</span>
    </el-menu-item>
 
    <sub-menu
      v-for="menu in selectMenuList"
      :key="menu.menuId"
      :menu="menu"
      :dynamicMenuRoutes="dynamicMenuRoutes"
    >
    </sub-menu>
  </el-menu>
</template>
<style> 
.tr-navbar .site-navbar__menu.el-menu {
  width: 180px !important;
}
.tr-navbar .site-sidebar__menu.el-menu {
  border-right: 0;
  background: transparent;
  float: left;
  padding-left: 50px;
  width: calc(100% - 600px);
  display: flex;
  overflow: auto;
}
ul::-webkit-scrollbar-button {
  display: block;
}
ul::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}
::-webkit-scrollbar-button:horizontal:single-button:start {
  width: 24px;
  cursor: pointer;
}
ul::-webkit-scrollbar-button:horizontal:single-button:end {
  width: 24px;
  cursor: pointer;
}
ul::-webkit-scrollbar-button:vertical:single-button:start {
  width: 16px;
  cursor: pointer;
}
ul::-webkit-scrollbar-button:vertical:single-button:end {
  width: 16px;
  cursor: pointer;
}
</style>
<style lang="scss">
  .site-sidebar__menu  {
    .iconfont {
      height: 40px!important;
      width: 40px!important;
      display: inline!important;
    } 
  } 
</style>
<script>
import SubMenu from "./sub-tree-menu";
import { isURL } from "@/utils/validate";
import { urlQueryParse } from "@/utils/index";
export default {
  data() {
    return {
      dynamicMenuRoutes: []
    };
  },
  props: {
    modType: {
      type: String,
      default: "vertical"
    }
  },
  components: {
    SubMenu
  },
  computed: {
    sidebarLayoutSkin: {
      get() {
        return this.$store.state.common.sidebarLayoutSkin;
      }
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      }
    },
    menuLayout: {
      get() {
        return this.$store.state.common.menuLayout;
      }
    },
    menuList: {
      get() {
        return this.$store.state.common.menuList;
      },
      set(val) {
        this.$store.commit("common/updateMenuList", val);
      }
    },
    // 2022-04-11 lyf 从选中navbar中的菜单过滤当前这部需要展示的菜单树
    selectMenuList() {
   
      if(this.menuLayout && !this.activeNavbar) {
        return []
      }
       
      // 如果顶部菜单没有打开 直接返回所有菜单
      if(!this.menuLayout) {
        return this.menuList
      }

      for(let i in this.menuList) {
        const m = this.menuList[i]
        if(m.menuId == this.activeNavbar) {
          return m.list
        }
      }

      return []

    },
    menuActiveName: {
      get() {
        return this.$store.state.common.menuActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMenuActiveName", val);
      }
    },
    mainTabs: {
      get() {
        return this.$store.state.common.mainTabs;
      },
      set(val) {
        this.$store.commit("common/updateMainTabs", val);
      }
    },
    mainTabsActiveName: {
      get() {
        return this.$store.state.common.mainTabsActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMainTabsActiveName", val);
      }
    },
    activeNavbar: {
      get() {
        return this.$store.state.common.activeNavbar;
      },
      set(val) {
        this.$store.commit("common/updateTabOpen", val);
      }
    },
    tabOpen: {
      get() {
        return this.$store.state.common.tabOpen;
      }
    }
  },
  watch: {
    $route: "routeHandle"
  },
  created() {
    this.menuList = JSON.parse(sessionStorage.getItem("menuList") || "[]");
    this.dynamicMenuRoutes = JSON.parse(
      sessionStorage.getItem("dynamicMenuRoutes") || "[]"
    );
    this.routeHandle(this.$route);
  },
  methods: {
    
    // 路由操作
    routeHandle(route) {
      if (this.tabOpen) {
        // tab选中, 不存在先添加
        var tab = this.mainTabs.filter(item => item.name === route.name)[0];
        if (!tab) {
          /*if (route.meta.isDynamic) {
              route = this.dynamicMenuRoutes.filter(item => item.name === route.name)[0]
              if (!route) {
                return console.error('未能找到可用标签页!')
              }
            }*/

          const url = window.location.href;
          const urlQuery = urlQueryParse(url);

          if (urlQuery) {
            // 2020-10-09 判断route是否包含query, 当前query是否和浏览器中的query一致
            // 判断是否一致 不一样则遍历路由找到相同path和query的路由
            for (var i in this.dynamicMenuRoutes) {
              const droute = this.dynamicMenuRoutes[i];
              // console.log('droute.path' , droute.path , 'route.path' , route.path)
              if (droute.path != route.path || droute.query) continue;
              const dquery = droute.query || droute.meta.query;

              if (JSON.stringify(urlQuery) == JSON.stringify(dquery)) {
                route = droute;
                break;
              }
            }
          } 
          tab = {
            menuId: route.meta.menuId ,
            name: route.name,
            title: route.meta.title,
            path: route.path,
            meta: route.meta,
            app: route.app || route.meta.app,
            query: route.query || route.meta.query,
            type: isURL(route.meta.iframeUrl) ? "iframe" : "module",
            iframeUrl: route.meta.iframeUrl || ""
          };
          if (tab.name == "home") {
            this.mainTabs = [tab].concat(this.mainTabs);
          } else {
            this.mainTabs = this.mainTabs.concat(tab);
          }
        }

        this.menuActiveName = tab.menuId + "";
        this.mainTabsActiveName = tab.name;
      }
    }
  }
};
</script>
