<template>
  <aside
    
    class="site-sidebar"
     :class="'site-sidebar--' + sidebarLayoutSkin"
  >
    <div class="site-sidebar__inner">
      <MenuTree />
    </div>
  </aside>
</template>

<script>
import MenuTree from "@/components/menu-tree";

export default {
  data() {
    return {};
  },
  computed: {
    sidebarLayoutSkin: {
      get() {
        return this.$store.state.common.sidebarLayoutSkin;
      }
    },
    menuLayout: {
      get() {
        return this.$store.state.common.menuLayout;
      }
    },
    
  },
  components: {
    MenuTree
  }
};
</script>
<style scoped>

ul{
  background:#E9F4FD;
}
li{
  background:#5fa6fb;
  color: #fff;
}
.site-sidebar__inner{
  background: #3e88de  !important;
}
/deep/.el-menu-item.is-active{
  background-color: #BFE1FD !important;
  color: #333333 !important;
  border-bottom: 3px solid #ffffff !important;
}

.el-menu-item:hover{
  color: #333 !important;
}
</style>